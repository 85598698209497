@import '../../assets/styles/shared';

.headerLink {
  color: $white !important;
  opacity: 0.8;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

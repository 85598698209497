// Headers
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  @include text-uppercase;
}

.list-centered {
  text-align: center;
  list-style-position: inside;
}

@mixin btn-link($link-color, $link-hover-color) {
  color: $link-color;

  &:hover,
  &:active,
  &.active {
    color: $link-hover-color;
    text-decoration: underline;
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

@mixin link($link-color, $link-hover-color) {
  @include btn-link($link-color, $link-hover-color);

  transition: color 0.15s ease-in-out;
}

.link-base {
  @include link($link-color, $link-hover-color);
}

.link-white {
  @include link($white, $gray-300);
}

.link-light {
  @include link($text-color-base, $white);
}

.link-dark {
  @include link($text-color-dark, $black);
}

.btn.btn-link {
  @include btn-link($link-color, $link-hover-color);
}

.btn.btn-link-light {
  @include btn-link($text-color-base, $white);
}

.btn.btn-link-dark {
  @include btn-link($text-color-dark, $black);
}

.btn.btn-link-white {
  @include btn-link($white, $gray-300);
}

/* stylelint-disable-next-line selector-no-qualifying-type */
a.disabled {
  color: $btn-link-disabled-color;
  pointer-events: none;
}

.btn.btn-anchor {
  display: inline;

  padding: 0;

  font: inherit;
  text-transform: inherit;
  letter-spacing: 0.02em;

  border: none;
}

.btn-anchor {
  display: inline;

  padding: 0;

  color: $link-color;

  background: none;
  border: none;
  outline: inherit;

  &:hover {
    color: $link-hover-color;
  }
}

// Button variants
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  color: $background;
  background-color: transparent;
  border-color: $border;

  @include hover {
    @include gradient-bg($background);

    color: color-yiq($background);
    border-color: $border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($border, 0.5);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $background;
    background-color: transparent;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }

    border-color: $active-border;
    box-shadow: none;

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($border, 0.5);
    }
  }

  &.btn-filled {
    @include gradient-bg($background);

    color: color-yiq($background);

    @include hover {
      // color: color-yiq($hover-background);
      @include gradient-bg($hover-background);

      color: color-yiq($background, $dark: $yiq-text-light, $light: $gray-200);

      // border-color: $hover-border;
      border-color: color-yiq($background);
    }

    &.disabled,
    &:disabled {
      @include gradient-bg($background);

      color: color-yiq($background);
      border-color: $border;
    }
  }
}

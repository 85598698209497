.btn {
  @include text-uppercase;

  font-family: $font-family-monospace;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;

  &:focus {
    outline: none;
  }
}

@import './variables';

.navbarWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed + 5;
}

.navbarContainer {
  @include text-uppercase;
  @include font-size(1.25rem);

  width: 100vw;
  padding-top: $top-space-desktop;
  padding-bottom: $top-space-desktop;
  margin-right: auto;
  margin-left: auto;

  &.mobileNavbar {
    @include media-breakpoint-down($collapse-breakpoint) {
      @include font-size(2.25rem);

      z-index: z-index-layer($overlay-layer-index, $zindex-fixed);
      padding-top: $top-space-mobile;
      padding-bottom: $top-space-mobile;
    }
  }
}

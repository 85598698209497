@use 'sass:math';

.form-label,
.text-label {
  @include text-uppercase;

  opacity: math.div(2, 3);
}

@each $state, $data in $form-validation-states {
  .#{$state}-feedback {
    @include text-uppercase;
  }
}
